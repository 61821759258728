import { createSelector, type PayloadAction } from '@reduxjs/toolkit';
import { createAppSlice } from '../../app/createAppSlice';
import type { AsphaltCompactionSpecification, CompactionSpecification, ReportType } from '../analysis/RollerAnalysis';
import dayjs from 'dayjs';
import type { ArcAuthDetails, ArcTokenResponse, LayerItem, ServiceItem } from '@shared/arcgisTypes';
import type { DynapacAuthDetails, DynapacProjectDetails, DynapacLayerDetails, DynapacObjectDetails } from '@shared/dynapacTypes';
import { setOrRemove, type PersistHandlers } from '../../tools/persistHelpers';
import type { SliceActions } from '../../tools/helpers';

export interface ConfigStatus {
  rollerFiles: boolean;
  boundaryFiles: boolean;
  testFiles: boolean;

  config: boolean;
}

export interface ArcSelectionState {
  feature?: ServiceItem | null;
  layer?: LayerItem | null;
  pavingLayer?: string | null;
}
const emptySelection: ArcSelectionState = {
  feature: null,
  layer: null,
  pavingLayer: null
};

export type ArcDataTypes = 'boundary' | 'tests';
type ArcSelectionsMap = Record<ArcDataTypes, ArcSelectionState>;

export interface ArcState {
  auth?: ArcAuthDetails | null;

  selection: ArcSelectionsMap;
}

// export type LayerStatus = 'downloading' | 'downloaded' | 'error';
// export interface ActiveLayerItem {
//   layerId: string;
//   layerName: string;

//   status: LayerStatus;
//   progress?: number;
// }

export interface DynapacSelectionState {
  project: DynapacProjectDetails | null;
  object: DynapacObjectDetails | null;
  layer: DynapacLayerDetails | null;
}

export interface DynapacState {
  auth?: DynapacAuthDetails;
  selection: DynapacSelectionState;
}

export const pavementLayerTypes = [
  'Base Course',
  'Layer 1',
  'Layer 2',
  'Layer 3',
  'Layer 4',
  'Wearing Course',
  'Corrector',
  'Patching',
  'Production Trial',
  'Construction Trial',
  'Other'
] as const;

export type PavementLayer = typeof pavementLayerTypes[number];

export interface OverviewSetup {
  jobId: string | null;
  reportType: ReportType;
  layer: PavementLayer | null;
  mixType: string | null;
  thickness: number | null;
}

const overviewDefault: OverviewSetup = {
  jobId: null,
  layer: null,
  mixType: null,
  thickness: null,
  reportType: 'ASPHALT'
};

export type DataPickerSource = 'files' | 'api';

export interface DataItem {
  name: string;

  key: string;
}

export type FileOrDataItem = File | DataItem;

export function isDataItem(obj: FileOrDataItem): obj is DataItem {
  return typeof obj === 'object' && obj !== null && 'name' in obj && 'key' in obj;
}

export function isFile(obj: FileOrDataItem): obj is File {
  return obj instanceof File;
}

export function areItemsEqual(a: FileOrDataItem, b: FileOrDataItem): boolean {
  if (isDataItem(a) && isDataItem(b)) {
    return a.key === b.key;
  }

  if (isFile(a) && isFile(b)) {
    return a === b;
  }

  return false;
}

export interface DataPickerState {
  /**
   * Stores either an array of File objects (for CSV files)
   * or an array of strings (IndexedDB keys for API JSON data)
   */
  rollerFiles: FileOrDataItem[];
  rollerSource: DataPickerSource;

  boundaryFiles: File[];
  boundarySource: DataPickerSource;

  testFiles: File[];

  compactionSpecs: CompactionSpecification[];
  overview: OverviewSetup;

  dynapac: DynapacState;
  arcgis: ArcState;
}

function rollerItemsBySource(items: (File | DataItem)[], src: DataPickerSource): File[] | DataItem[] {
  if (src === 'files') {
    return items.filter((f) => f instanceof File) as File[];
  }

  return items.filter(isDataItem) as DataItem[];
}

// checks for a valid auth object (does NOT check expiration)
function validateAuth(auth: ArcAuthDetails | null): boolean {
  return !!auth &&
    !!auth.accessToken &&
    !!auth.refreshToken &&
    !!auth.user &&
    !!auth.user.orgId;
}

function specReviver(key: string, value: string) {
  if (key === 'timeLimit') {
    return dayjs.duration(value);
  }

  return value;
}

function dayjsReviver(key: string, value: string) {
  if (key === 'expiration') {
    return dayjs.utc(value);
  }

  return value;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function parseJson<T>(json: string | null, reviver?: (key: string, value: string) => any): T | null {
  if (!json) {
    return null;
  }
  return JSON.parse(json, reviver) as T;
}

const storedArcSelection = parseJson<ArcSelectionsMap>(localStorage.getItem('setup:arcgis:selection'));
let storedAuth = parseJson<ArcAuthDetails>(localStorage.getItem('setup:arcgis:auth'), dayjsReviver);
if (!validateAuth(storedAuth)) {
  localStorage.removeItem('setup:arcgis:auth');
  storedAuth = null;
}

const storedDynSelection = {
  project: null,
  object: null,
  layer: null,
  ...parseJson<DynapacSelectionState>(localStorage.getItem('setup:dynapac:selection'))
};

let storedDynAuth = parseJson<DynapacAuthDetails>(localStorage.getItem('setup:dynapac:auth'), dayjsReviver);
if (!storedDynAuth || !storedDynAuth.accessToken) {
  localStorage.removeItem('setup:dynapac:auth');
  storedDynAuth = null;
}

const storedSpecs = localStorage.getItem('setup:specs');
const storedOverview = parseJson<OverviewSetup>(localStorage.getItem('setup:overview'));

const initialState: DataPickerState = {
  rollerFiles: [],
  rollerSource: 'files',

  boundaryFiles: [],
  boundarySource: 'files',

  testFiles: [],

  compactionSpecs: storedSpecs ? JSON.parse(storedSpecs, specReviver) as AsphaltCompactionSpecification[] : [],

  overview: {
    ...overviewDefault,
    ...storedOverview
  },

  arcgis: {
    auth: storedAuth ?? undefined,
    selection: {
      boundary: {
        ...emptySelection,
        ...storedArcSelection?.boundary
      },
      tests: {
        ...emptySelection,
        ...storedArcSelection?.tests
      }
    }
  },
  dynapac: {
    auth: storedDynAuth ?? undefined,
    selection: {
      ...storedDynSelection
    }
  }
};

/**
 * This slice is responsible for managing the state of the data picker.
 *
 * We use the custom createAppSlice (vs createSlice) to allow for async thunks.
 */
export const dataPickerSlice = createAppSlice({
  name: 'dataPicker',
  initialState,
  reducers: (create) => ({
    setRollerFiles: create.reducer((state, action: PayloadAction<FileOrDataItem[]>) => {
      state.rollerFiles = action.payload;
    }),
    addRollerFile: create.reducer((state, action: PayloadAction<FileOrDataItem>) => {
      if (state.rollerFiles.some((f) => areItemsEqual(f, action.payload))) {
        return;
      }
      // only add if it doesn't already exist
      state.rollerFiles.push(action.payload);
    }),
    removeRollerFile: create.reducer((state, action: PayloadAction<FileOrDataItem>) => {
      state.rollerFiles = state.rollerFiles.filter((f) => {
        if (f instanceof File && action.payload instanceof File) {
          return f !== action.payload;
        }

        if (isDataItem(f) && isDataItem(action.payload)) {
          return f.key !== action.payload.key;
        }
      });
    }),
    setRollerSource: create.reducer((state, action: PayloadAction<DataPickerSource>) => {
      state.rollerSource = action.payload;
    }),

    setBoundaryFiles: create.reducer((state, action: PayloadAction<File[]>) => {
      state.boundaryFiles = action.payload;
    }),
    setBoundarySource: create.reducer((state, action: PayloadAction<DataPickerSource>) => {
      state.boundarySource = action.payload;
    }),

    setTestFiles: create.reducer((state, action: PayloadAction<File[]>) => {
      state.testFiles = action.payload;
    }),

    setCompactionSpecs: create.reducer((state, action: PayloadAction<CompactionSpecification[]>) => {
      state.compactionSpecs = action.payload;
    }),

    updateOverview: create.reducer((state, action: PayloadAction<Partial<OverviewSetup>>) => {
      if (action.payload.jobId !== undefined) {
        state.overview.jobId = action.payload.jobId;
      }
      if (action.payload.layer !== undefined) {
        state.overview.layer = action.payload.layer;
      }
      if (action.payload.mixType !== undefined) {
        state.overview.mixType = action.payload.mixType;
      }
      if (action.payload.thickness !== undefined) {
        state.overview.thickness = action.payload.thickness;
      }
      if (action.payload.reportType !== undefined) {
        state.overview.reportType = action.payload.reportType;
      }
    }),

    setDynapacAuth: create.reducer((state, action: PayloadAction<DynapacAuthDetails>) => {
      state.dynapac.auth = action.payload;
    }),

    updateDynapacSelection: create.reducer((state, action: PayloadAction<Partial<DynapacSelectionState>>) => {
      if (action.payload.project !== undefined) {
        state.dynapac.selection.project = action.payload.project;
      }
      if (action.payload.object !== undefined) {
        state.dynapac.selection.object = action.payload.object;
      }
      if (action.payload.layer !== undefined) {
        state.dynapac.selection.layer = action.payload.layer;
      }
    }),

    setArcAuth: create.reducer((state, action: PayloadAction<ArcAuthDetails | null>) => {
      state.arcgis.auth = action.payload;

      // reset selection on login
      state.arcgis.selection = {
        boundary: {
          ...emptySelection
        },
        tests: {
          ...emptySelection
        }
      };
    }),

    updateArcToken: create.reducer((state, action: PayloadAction<ArcTokenResponse>) => {
      if (state.arcgis.auth) {
        state.arcgis.auth.accessToken = action.payload.accessToken;
        state.arcgis.auth.expiration = action.payload.expiration;
      }
    }),

    updateArcSelection: create.reducer((state, action: PayloadAction<{ type: ArcDataTypes } & Partial<ArcSelectionState>>) => {
      if (action.payload.feature !== undefined) {
        state.arcgis.selection[action.payload.type].feature = action.payload.feature;
      }
      if (action.payload.layer !== undefined) {
        state.arcgis.selection[action.payload.type].layer = action.payload.layer;
      }
      if (action.payload.pavingLayer !== undefined) {
        state.arcgis.selection[action.payload.type].pavingLayer = action.payload.pavingLayer;
      }
    }),
  }),

  selectors: {
    selectRollerFilesOrKeys: (state) => state.rollerFiles,
    selectRollerFiles: createSelector(
      [
        (state: DataPickerState) => state.rollerFiles,
      ],
      (filesOrKeys) => filesOrKeys.filter((f) => f instanceof File) as File[]
    ),
    selectRollerKeys: createSelector(
      [
        (state: DataPickerState) => state.rollerFiles,
      ],
      (filesOrKeys) => filesOrKeys.filter(isDataItem) as DataItem[]
    ),
    selectRollerItemsByCurrentSource: createSelector(
      [
        (state: DataPickerState) => state.rollerFiles,
        (state: DataPickerState) => state.rollerSource
      ],
      (files, source) => rollerItemsBySource(files, source)
    ),
    selectRollerSource: (state) => state.rollerSource,

    selectBoundaryFiles: (state) => state.boundaryFiles,
    selectBoundarySource: (state) => state.boundarySource,

    selectTestFiles: (state) => state.testFiles,

    selectCompactionSpecs: (state) => state.compactionSpecs,
    selectOverview: (state) => state.overview,
    selectReportType: (state) => state.overview.reportType,

    selectConfigStatus: createSelector(
      [
        (state: DataPickerState) => state.rollerFiles,
        (state: DataPickerState) => state.rollerSource,
        (state: DataPickerState) => state.boundaryFiles,
        (state: DataPickerState) => state.testFiles,
        (state: DataPickerState) => state.compactionSpecs
      ], (rollerFiles, rollerSource, boundaryFiles, testFiles, specs): ConfigStatus => ({
        rollerFiles: rollerItemsBySource(rollerFiles, rollerSource).length > 0,
        boundaryFiles: boundaryFiles.length > 0,
        testFiles: testFiles.length > 0,
        config: specs.length > 0
      })
    ),

    selectDynapacAuth: (state) => state.dynapac.auth,
    selectDynapacIsAuth: (state) => !!state.dynapac.auth,
    selectDynapacIsExpired: (state) => state.dynapac.auth?.expiration.subtract(5, 'minutes').isBefore(dayjs()),
    selectDynapacAuthState: createSelector(
      [
        (state: DataPickerState) => state.dynapac.auth,
        (state: DataPickerState) => state.dynapac.auth?.expiration
      ], (auth, expiration) => {
        console.log('combining auth state');
        return {
          auth,
          isAuth: !!auth,
          getIsExpired: () => expiration?.subtract(5, 'minutes').isBefore(dayjs())
        }
      }
    ),
    selectDynapacSelection: (state) => state.dynapac.selection,

    selectArcAuthState: (state) => state.arcgis.auth,
    selectArcIsAuth: (state) => state.arcgis.auth?.accessToken && state.arcgis.auth?.refreshToken,
    selectArcIsExpired: (state) => state.arcgis.auth?.expiration.subtract(1, 'minutes').isBefore(dayjs()),
  },
});

// ----- selector factory methods -----
// selector factories are used when we want to use a selector from multiple components
// and still have the previous values cached properly for each component
export const makeSelectArcSelectionByType = () => {
  const selectArcSelectionByType = createSelector(
    [
      (state: DataPickerState) => state.arcgis.selection,
      (_state: DataPickerState, type: ArcDataTypes) => type
    ],
    (selection, type) => selection[type]
  );

  return selectArcSelectionByType;
}

export const makeSelectRollerItemsBySource = () => {
  const selectRollerItemsBySource = createSelector(
    [
      (state: DataPickerState) => state.rollerFiles,
      (_: DataPickerState, source: DataPickerSource) => source
    ],
    (files, source) => rollerItemsBySource(files, source)
  );

  return selectRollerItemsBySource;
}
// ----- end selector factory methods -----


export const {
  setRollerFiles,
  addRollerFile,
  removeRollerFile,
  setRollerSource,

  setBoundaryFiles,
  setBoundarySource,

  setTestFiles,

  setCompactionSpecs,
  updateOverview,

  setDynapacAuth,
  updateDynapacSelection,

  setArcAuth,
  updateArcToken,
  updateArcSelection
} = dataPickerSlice.actions;

export const {
  selectRollerFilesOrKeys,
  selectRollerFiles,
  selectRollerKeys,
  selectRollerItemsByCurrentSource,
  selectRollerSource,

  selectBoundaryFiles,
  selectTestFiles,

  selectCompactionSpecs,
  selectOverview,
  selectReportType,

  selectConfigStatus,

  selectDynapacAuth,
  selectDynapacIsAuth,
  selectDynapacIsExpired,
  selectDynapacAuthState,
  selectDynapacSelection,

  selectArcAuthState,
  selectArcIsAuth,
  selectArcIsExpired,
} = dataPickerSlice.selectors;

export const ignoredDataPickerActions = [
  setBoundaryFiles.type,
  setRollerFiles.type,
  setTestFiles.type,

  setCompactionSpecs.type,

  setDynapacAuth.type,

  setArcAuth.type,
  updateArcToken.type
];

export const ignoredDataPickerPaths = [
  'dataPicker.rollerData',
  'dataPicker.rollerFiles',
  'dataPicker.boundaries',
  'dataPicker.boundaryFiles',
  'dataPicker.compactionSpecs',
  'dataPicker.dynapac.auth.expiration',
  'dataPicker.arcgis.auth.expiration'
];

export type DataPickerActions = typeof dataPickerSlice.actions;
export type DataPickerActionTypes = SliceActions<typeof dataPickerSlice.actions>;

export const dataPickerPersistHandlers: PersistHandlers<DataPickerActionTypes> = {
  'dataPicker/setDynapacAuth': (state) => {
    setOrRemove('setup:dynapac:auth', state.dataPicker.dynapac.auth);
  },
  'dataPicker/updateDynapacSelection': (state) => {
    setOrRemove('setup:dynapac:selection', state.dataPicker.dynapac.selection);
  },
  'dataPicker/setArcAuth': (state) => {
    setOrRemove('setup:arcgis:auth', state.dataPicker.arcgis.auth);
  },
  'dataPicker/updateArcToken': (state) => {
    setOrRemove('setup:arcgis:auth', state.dataPicker.arcgis.auth);
  },
  'dataPicker/updateArcSelection': (state) => {
    setOrRemove('setup:arcgis:selection', state.dataPicker.arcgis.selection);
  },
  'dataPicker/setCompactionSpecs': (state) => {
    localStorage.setItem('setup:specs', JSON.stringify(state.dataPicker.compactionSpecs));
  },

  'dataPicker/updateOverview': (state) => {
    localStorage.setItem('setup:overview', JSON.stringify(state.dataPicker.overview));
  }
};